import React from 'react'
import HealthCheckView from './health-check-view'

function HealthCheckController() {
  return (
    <HealthCheckView />
  )
}

export default HealthCheckController
